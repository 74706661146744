import { ErrorHandler, NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { createErrorHandlerFactory } from '@appsignal/angular';
import Appsignal from '@appsignal/javascript';
import { plugin as pluginPathDecorator } from '@appsignal/plugin-path-decorator';
import { ENVIRONMENT } from '@domains';
import { environment } from '@driver/../environments/environment';
import { AppRoutingModule } from '@driver/app-routing.module';
import { AppComponent } from '@driver/app.component';
import { CoreModule } from '@driver/import-modules/core.module';
import { RsplAuthModule } from '@rspl-auth';

export const appsignal = new Appsignal({
  key: environment.appSignal,
  namespace: 'driver',
  revision: '{{POST_BUILD_ENTERS_APP_SIGNAL_HASH_HERE}}',
});
appsignal.use(pluginPathDecorator());

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    RsplAuthModule.forRoot(),
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en' },
    ...(environment.isProductionBuild ||
    environment.isStagingBuild ||
    environment.isDevBuild
      ? [
          {
            provide: ErrorHandler,
            useFactory: createErrorHandlerFactory(appsignal),
          },
        ]
      : []),
    { provide: ENVIRONMENT, useValue: environment },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
