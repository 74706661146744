<mat-toolbar *ngIf="isLoggedIn && isReady">
  <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
    <div *ngIf="isStaging || isDemo || isDev" class="header-staging">
      {{ isDemo ? "demo" : isDev ? "dev" : "staging" }}
    </div>
    <a
      [routerLink]="['/', 'donations']"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <img src="assets/images/rspl_logo.png" alt="Resupply" />
    </a>
    <div>
      <!-- <button
        color="warn"
        *ngIf="locationState !== 'granted'"
        mat-mini-fab
        (click)="setLocation(locationState)"
      >
        <mat-icon>wrong_location</mat-icon>
      </button>-->
      <rspl-theme-button></rspl-theme-button>
      <a
        *ngIf="zendesk"
        mat-icon-button
        matTooltip="Help Center"
        [matTooltipShowDelay]="500"
        target="_blank"
        [href]="zendesk"
      >
        <mat-icon>help_outline</mat-icon>
      </a>
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="logout()">
          <mat-icon>logout</mat-icon>Sign Out
        </button>
      </mat-menu>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<div
  class="content"
  [ngClass]="{ 'logged-in': isLoggedIn }"
  *ngIf="versionValid && isReady"
>
  <router-outlet></router-outlet>
</div>
<ng-template #Location>
  <div class="location-dialog" fxLayout="column" fxLayoutGap="10px">
    <h2 fxFlex="100">PLEASE ENABLE LOCATION SERVICES</h2>
    <div fxFlex="100" fxLayoutAlign="center center">
      <mat-icon>my_location</mat-icon>
    </div>
    <ul>
      <li>Best app experience</li>
      <li>Optimized routes</li>
      <li>Allow Resupply to support your service effectively</li>
    </ul>
    <p>
      *Failure to enable location could result in a lower Resupply score & less
      work
    </p>
    <button class="primary-btn" (click)="locationDialog?.close()">
      OK, got it!
    </button>
  </div>
</ng-template>
