import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LaddaModule } from 'angular2-ladda';
import { QRCodeModule } from 'angularx-qrcode';
import {
  MatTableExporterDirective,
  MatTableExporterModule
} from 'mat-table-exporter';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import { MapService, NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { environment } from '@driver/../environments/environment';
import { MaterialImportsModule } from '@driver/import-modules/material-imports.module';
import { RsplImportsModule } from '@driver/import-modules/rspl-imports.module';

export const customCurrencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  allowZero: true,
  decimal: '.',
  precision: 2,
  prefix: '$ ',
  suffix: '',
  thousands: ',',
  nullable: false,
  min: 0,
  inputMode: CurrencyMaskInputMode.NATURAL,
};

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MaterialImportsModule,
    RsplImportsModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatTableExporterModule,
    RouterModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    LaddaModule.forRoot({
      style: 'zoom-in',
    }),
    QRCodeModule,
    NgxMapboxGLModule.withConfig({
      accessToken: environment.mapBoxAccessToken,
    }),
  ],
  exports: [
    FlexLayoutModule,
    MaterialImportsModule,
    RsplImportsModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableExporterDirective,
    NgxCurrencyModule,
    CommonModule,
    LaddaModule,
    QRCodeModule,
    NgxMapboxGLModule
  ],
  providers: [CurrencyPipe, DatePipe, MapService],
})
export class CoreModule {}
