<h2 mat-dialog-title>ETA</h2>
<div mat-dialog-content class="eta-dialog">
  <p>Please select the ETA for today's <b>{{data.donation.partOfDay.toUpperCase()}}</b> donation <b>{{data.donation.donationCode}}</b> for <b>{{data.donation.donor.name}}</b> at {{data.donation.address.fullAddress}}:</p>
  <div fxLayout="column" fxLayoutGap="10px">
    <button class="primary-btn" [ngClass]="{'filled-btn' : eta === '08:00 am - 10:00 am'}" (click)="eta = '08:00 am - 10:00 am'">08:00 am - 10:00 am</button>
    <button class="primary-btn" [ngClass]="{'filled-btn' : eta === '10:00 am - 12:00 pm'}" (click)="eta = '10:00 am - 12:00 pm'">10:00 am - 12:00 pm</button>
    <button class="primary-btn" [ngClass]="{'filled-btn' : eta === '12:00 pm - 02:00 pm'}" (click)="eta = '12:00 pm - 02:00 pm'">12:00 pm - 02:00 pm</button>
    <button class="primary-btn" [ngClass]="{'filled-btn' : eta === '02:00 pm - 04:00 pm'}" (click)="eta = '02:00 pm - 04:00 pm'">02:00 pm - 04:00 pm</button>
  </div>
  <div fxLayout="row" style="margin-top: 20px">
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>ETA Note</mat-label>
      <textarea [(ngModel)]="etaNote" matInput></textarea>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="flex-end">
  <button *ngIf="data.canCancel" mat-button (click)="onChoice(false)">Cancel</button>
  <button class="primary-btn" (click)="onChoice(true)" [disabled]="!eta" [ladda]="isSubmitting">Save</button>
</div>
