import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Donation, IDonation } from '@domains';
import { DonationsService } from '@rspl-api';
import { take } from 'rxjs';

@Component({
  selector: 'app-eta-donation-dialog',
  templateUrl: './eta-donation-dialog.component.html',
  styleUrls: ['./eta-donation-dialog.component.scss'],
})
export class EtaDonationDialogComponent implements OnInit {
  public eta!: string;
  public etaNote!: string;
  isSubmitting: boolean = false;

  public constructor(
    public reference: MatDialogRef<EtaDonationDialogComponent>,
    private donationsService: DonationsService,
    @Inject(MAT_DIALOG_DATA)
    public data: { donation: Donation; canCancel: boolean }
  ) {}

  ngOnInit(): void {
    this.eta = this.data.donation.eta || '';
    this.etaNote = this.data.donation.etaNote || '';
  }

  public onChoice(choice: boolean): void {
    if (choice && this.data.donation.id) {
      this.isSubmitting = true;
      this.donationsService
        .update(
          this.data.donation.id,
          new Donation({
            ...this.data.donation,
            eta: this.eta,
            etaNote: this.etaNote,
            requestEta: false,
            meta: {
              ...this.data.donation?.meta,
              etaProvidedAt: new Date().toISOString(),
            },
          } as IDonation)
        )
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.isSubmitting = false;
            this.reference.close(choice);
          },
          error: () => {
            this.isSubmitting = false;
          },
        });
    } else {
      this.reference.close(choice);
    }
  }
}
