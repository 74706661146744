import { NgModule } from '@angular/core';
import { environment } from '@driver/../environments/environment';

import { RsplApiModule } from '@rspl-api';
import { RsplMapModule } from '@rspl-map';
import { RsplUIModule, RsplUiConfig } from '@rspl-ui';
import { RsplVersionConfig, RsplVersionModule } from '@rspl-version';

@NgModule({
  declarations: [],
  imports: [
    RsplUIModule.forRoot(),
    RsplMapModule.forRoot(),
    RsplVersionModule.forRoot({
      currentHash: '{{POST_BUILD_ENTERS_HASH_HERE}}',
    } as RsplVersionConfig),
    RsplApiModule.forRoot()
  ],
  exports: [
    RsplApiModule,
    RsplUIModule,
    RsplMapModule,
    RsplVersionModule,
  ],
  providers: [],
})
export class RsplImportsModule {}
