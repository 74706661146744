import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  Contact,
  Donation,
  DonationPartnerState,
  Truck,
  Xmile,
} from '@domains';
import {
  DonationsService,
  LocalStorageService,
  TrucksService,
} from '@rspl-api';
import { AuthenticationService } from '@rspl-auth';
import * as moment from 'moment';
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MainService {
  selectedTruck: string | null;
  selectedView: 'today' | 'tomorrow' | 'choose';
  date: string;
  loaded = false;
  xmiles?: Array<Xmile>;
  donations: Donation[] = [];

  private donationsSubject = new BehaviorSubject<Donation[]>([]);
  public donations$ = this.donationsSubject.asObservable();

  public constructor(
    private router: Router,
    private auth: AuthenticationService,
    private donationsService: DonationsService,
    private trucksService: TrucksService,
    private localStorage: LocalStorageService
  ) {
    this.selectedTruck = this.localStorage.getItem('rsp-selected-truck');
    this.selectedView = 'today';
    this.date = moment().format('YYYY-MM-DD');
  }

  getDonations(
    date: string,
    truckId: string | null = this.selectedTruck,
    selectedView: 'today' | 'tomorrow' | 'choose' = 'today'
  ): Observable<Donation[]> {
    this.loaded = true;
    if (!truckId) {
      return of([]);
    }
    this.date = date;
    this.selectedTruck = truckId;
    this.localStorage.setItem(
      'rsp-selected-truck',
      this.selectedTruck?.toString() || ''
    );
    this.selectedView = selectedView;
    return this.donationsService
      .filter({
        expand: 'charity',
        date,
        vehicle_id: truckId,
        active: true,
        order: 'created_at',
        order_direction: 'asc',
      })
      .pipe(
        map((response) => {
          this.donationsSubject.next(response.results);
          return response.results;
        })
      );
  }

  getDonationsInSpan(
    dateBefore: string,
    dateAfter: string,
    truckId: string | null = this.selectedTruck,
    selectedView: 'today' | 'tomorrow' | 'choose' = 'today'
  ): Observable<Donation[]> {
    this.loaded = true;
    if (!truckId) {
      return of([]);
    }
    this.selectedTruck = truckId;
    this.localStorage.setItem(
      'rsp-selected-truck',
      this.selectedTruck?.toString() || ''
    );
    this.selectedView = selectedView;
    return this.donationsService
      .filter({
        expand: 'charity',
        date_after: dateAfter,
        date_before: dateBefore,
        vehicle_id: truckId,
        active: true,
        order: 'created_at',
        order_direction: 'asc',
      })
      .pipe(
        map((response) => {
          return response.results;
        })
      );
  }

  goToValidStep(donation: Donation, currentStep?: string, hasXmiles = false) {
    if (donation.date) {
      this.date = donation.date;
    }
    let step = this.getValidStep(donation, currentStep, hasXmiles);
    if (!currentStep || currentStep !== step) {
      if (
        this.auth.partner?.meta?.canAccessJourneys &&
        ['load-up', 'complete'].includes(step)
      ) {
        this.router.navigate(['donations']);
      } else if (['load-up', 'complete', 'extra-miles'].includes(step)) {
        this.router.navigate(['donations', step]);
      } else {
        this.router.navigate(['donations', donation.id, step]);
      }
    }
  }

  getValidStep(
    donation: Donation,
    currentStep: string | undefined,
    hasXmiles = false
  ): string {
    const spec = donation.adjustedSpecification;
    let donationContent: number = 0;
    if (spec) {
      donationContent = spec.xlarge + spec.large + spec.medium;
    }
    let step = '';
    switch (donation.partnerState) {
      case DonationPartnerState.arrived:
        step = 'quote-calculator';
        break;
      case DonationPartnerState.quote_sent:
        if (!donation.payment?.authCompleted) {
          if (currentStep === 'quote-calculator') {
            step = currentStep;
          } else {
            step = 'awaiting-user-acceptance';
          }
        } else if (
          this.photosRequired(donation) &&
          !(
            (donation.content?.filter((x) => (x.photo?.length || 0) > 0)
              .length || 0) === donationContent
          )
        ) {
          if (currentStep === 'accepted-quote') {
            step = currentStep;
          } else {
            step = 'picture-gallery';
          }
        } else {
          step = 'load-up';
        }
        break;
      case DonationPartnerState.payment_skipped:
        if (
          this.photosRequired(donation) &&
          (donation.content?.length || 0) !== this.numOfPhotosRequired(donation)
        ) {
          step = 'picture-gallery';
        } else {
          step = 'load-up';
        }
        break;
      case DonationPartnerState.en_routed_to_store:
        step = 'load-up';
        break;
      case DonationPartnerState.arrived_at_store:
        step = 'accept-content';
        break;
      case DonationPartnerState.completed:
        if (
          donation.content?.length !== donation.acceptedContent?.length &&
          donation.meta?.declinedExtraMile !== true &&
          !donation.extraMileId &&
          hasXmiles
        ) {
          step = 'extra-miles';
        } else {
          step = 'complete';
        }
        break;
      default:
        step = 'start-donation';
    }
    return step;
  }

  private photosRequired(donation: Donation): boolean {
    const spec = donation.adjustedSpecification;
    if (spec) {
      const donationContent: number = spec.xlarge + spec.large + spec.medium;
      return donationContent > 0;
    } else {
      return true;
    }
  }

  private numOfPhotosRequired(donation: Donation): number {
    const spec = donation.adjustedSpecification;
    if (spec) {
      return spec.xlarge + spec.large + spec.medium;
    } else {
      return 0;
    }
  }

  getPaymentStatusLabel(donation: Donation): string {
    if (donation.payment?.completed) {
      return 'PAYMENT CAPTURED: Payment completely successful, funds currently transferring, please allow 1-2 business days.';
    } else if (donation.payment?.authCompleted) {
      return 'PAYMENT AUTHORIZED: Donor successfully authorized payment, bring the donations to the charity and complete the drop through the app to receive funds.';
    } else if (donation.payment?.setupCompleted) {
      return 'PAYMENT SET UP COMPLETE: Waiting for the donor to accept the quote.';
    } else {
      return '/';
    }
  }

  declineExtraMiles(donations: Array<Donation>) {
    const updates$: Array<Observable<any>> = new Array<Observable<any>>();
    donations.forEach((donation, i) => {
      if (donation.id)
        updates$.push(
          this.donationsService
            .update(
              donation.id,
              new Donation({
                ...donation,
                meta: {
                  ...donation.meta,
                  declinedExtraMile: true,
                },
              })
            )
            .pipe(
              catchError((err) => of('ERROR')),
              take(1)
            )
        );
    });
    return forkJoin(updates$).pipe(take(1));
  }

  setExtraMile(donations: Array<Donation>, xmile: Xmile) {
    const updates$: Array<Observable<any>> = new Array<Observable<any>>();
    donations.forEach((donation, i) => {
      if (donation.id)
        updates$.push(
          this.donationsService
            .update(
              donation.id,
              new Donation({
                ...donation,
                extraMileId: xmile.id,
              })
            )
            .pipe(
              catchError((err) => of('ERROR')),
              take(1)
            )
        );
    });
    return forkJoin(updates$).pipe(take(1));
  }

  updateTruckContact(truck: Truck) {
    return this.auth.authData$.pipe(
      take(1),
      switchMap((authData) => {
        const contact: Contact = new Contact({
          email: authData?.identity,
          name: authData?.name || '',
          phone: authData?.phone,
        });
        if (truck.id && !this.areSameContacts(truck?.driverContact, contact)) {
          return this.trucksService.update(
            truck.id,
            new Truck({
              ...truck,
              driverContact: contact,
            })
          );
        }
        return of({});
      })
    );
  }

  areSameContacts(
    contact1?: Contact | null,
    contact2?: Contact | null
  ): boolean {
    return (
      (!contact1 && !contact2) ||
      (contact1?.email === contact2?.email &&
        contact1?.name === contact2?.name &&
        contact1?.phone === contact2?.phone)
    );
  }
}
